.accordion-item:first-of-type .accordion-button {

}

.accordion-button {
    background-color: unset !important;
    box-shadow: unset !important;
    justify-content: center;
}
.accordion-button:after {
    margin-left: 10px;
}
.contact-us-btn .accordion-button:after {
    width: 0;
}
.utr-image {
    min-height: 150px;
    max-height: 150px;
    height: 100%;
    display: flex;
    justify-content: center;
}

.utr-image img {
    max-width: 130px;
    height: 100%;
    width: 100%;
    object-fit: cover;
}